import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { FC } from 'react';

import NotFoundWrapper from '@/components/common/404/styles/NotFoundWrapper';
import { routePaths } from '@/utils/routePaths';

interface Props {
  login?: boolean;
  dashboardUrl?: string;
}

const NotFoundPage: FC<Props> = ({ login, dashboardUrl }) => {
  const { push } = useRouter();

  return (
    <NotFoundWrapper
      sx={{
        height: login ? '100vh' : '100%',
        m: dashboardUrl ? -5 : 0,
        width: dashboardUrl ? 'calc(100% + 80px)' : '100%',
        borderRadius: dashboardUrl ? 2.5 : 0,
      }}
    >
      <Grid display="flex" flexDirection="column" alignItems="center">
        <Image src="/images/404.png" alt="404" width={392} height={180} />
        <Typography variant="h1" sx={{ mt: 11.5, mb: 2.5 }}>
          Oops!
        </Typography>
        <Typography variant="h4" sx={{ mb: 5 }}>
          the page you&apos;re looking for cannot be found
        </Typography>
        {login && (
          <Button onClick={() => push(routePaths.commonLogin)}>
            Go to Login
          </Button>
        )}
        {dashboardUrl && (
          <Button onClick={() => push(dashboardUrl)}>Go to Dashboard</Button>
        )}
      </Grid>
    </NotFoundWrapper>
  );
};

export default NotFoundPage;
